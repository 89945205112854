import { Component, OnInit } from '@angular/core';
import { AppHandlerService, UserService } from 'src/app/shared/services/services.api';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/shared/model/model.api';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { Sexo } from 'src/app/shared/model/constantes';
@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.css']
})
export class EditUserProfileComponent implements OnInit {
  
  errors: string[] = [];
  userProfile: User;
  userProfileForm: FormGroup;
  isLogin: boolean;
  mnSel: number = 0;
  id : string = "";
  sexos: SelectItem[];
  constructor(
    private appSrv : AppHandlerService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router, 
    private userProfileSrv: UserService,
    private _location: Location
  ) { }

  ngOnInit() {

    this.userProfile = new User();

    this.userProfile.fechaNacimiento = null;

    this.loadDropdown();
    this.isLogin=this.appSrv.isLogin();

    this.createForm(); 

    if (this.isLogin){
      this.id = this.appSrv.getCurrentUser().id;
    this.userProfileSrv.getById(this.appSrv.getCurrentUser().id).subscribe(resp => {
      this.userProfile = resp;
      this.userProfile.fechaNacimiento = new Date(this.userProfile.fechaNacimiento);
      //console.log(this.userProfile);

    // this.userProfileForm.controls['fullName'].setValue(this.userProfile.fullName);
    // this.userProfileForm.controls['userName'].setValue(this.userProfile.userName);
    // this.userProfileForm.controls['email'].setValue(this.userProfile.email);

    this.resetForm();
    
    });
    }
    else{
      this.router.navigate(['']);
    }

  }


  assignValues(){
    Object.assign(this.userProfile,this.userProfileForm.value);
  }

   backClicked() {
  this._location.back();
}
  
  createForm(){
    this.userProfileForm = this.fb.group(this.userProfile, { validator: this.comparePasswords });
    this.userProfileForm.controls['fullName'].setValidators(Validators.required);
    this.userProfileForm.controls['userName'].setValidators(Validators.required);
    this.userProfileForm.controls['email'].setValidators([Validators.email, Validators.required, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}')]);
    this.userProfileForm.controls['telefono'].setValidators(Validators.pattern("^\\+(?:[0-9] ?){6,14}[0-9]$"));
    this.userProfileForm.controls['fechaNacimiento'].setValidators(Validators.required);

  }


  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get('confirmPassword');
    //passwordMismatch
    //confirmPswrdCtrl.errors={passwordMismatch:true}
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb.get('password').value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else
        confirmPswrdCtrl.setErrors(null);
    }
  }


  save(){

    //limpiar errores previos
    this.errors = [];

    //console.log(this.userProfile);
    this.assignValues(); 

    //console.log(this.userProfile);

    this.userProfileSrv.update(this.userProfile.id, this.userProfile).subscribe((resp:any) =>{

      if (resp.succeeded || resp.errors == undefined) {
        setTimeout(()=>{    
          this.toastr.info('Actualizado con éxito ','Informacion de perfil',{
            positionClass: 'toast-top-center'
          });  
    }, 500);
    //this.resetForm();
    this.router.navigateByUrl('/profile');
      }else{
        resp.errors.forEach(element => {
          switch (element.code) {
            case 'DuplicateUserName':
              setTimeout(()=>{    
                this.toastr.error('Usuario '+this.userProfile.userName+' no disponible','Registro Fallido',{
                  positionClass: 'toast-top-center'
                });  
          }, 500);
        
              break;

            default:
            this.toastr.error(element.description,'Registro fallido');
              break;
          }
        });
      }

    },(err) => {
      this.errors = this.appSrv.getErrosMessages(err);    
    });


  }


  resetForm() {

    this.userProfileForm.reset(this.userProfile);
    let fechaNacimiento = this.formatearFecha(this.userProfile.fechaNacimiento);
    this.userProfileForm.controls['fechaNacimiento'].setValue(fechaNacimiento);
  }


  loadDropdown() {

    //sexos
    this.sexos = [];
    this.sexos.push({ label: "Seleccionar sexo", value: null });
    this.sexos.push({ label: "Femenino", value: Sexo.Femenino });
    this.sexos.push({ label: "Masculino", value: Sexo.Masculino });
    this.sexos.push({ label: "Otro", value: null });
  }

  formatearFecha(fecha: Date) {

    //console.log(fecha);
    let fechaFormateada = "";

    fechaFormateada = "" + fecha.getFullYear();

    if ((fecha.getMonth() + 1) < 10) {
      fechaFormateada += "-0" + (fecha.getMonth() + 1);
    }
    else {
      fechaFormateada += "-" + (fecha.getMonth() + 1);
    }

    if (fecha.getDate() < 10) {
      fechaFormateada += "-0" + fecha.getDate();
    }
    else {
      fechaFormateada += "-" + fecha.getDate();
    }

    return fechaFormateada;
  }

  handlerError(res:any ){
    this.errors=[];    
    res.errors.forEach(element => {
      this.errors.push(element.description);    
    });
  }

  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
    document.getElementById("navbar-dat-training").style.display="none";
  }
}
