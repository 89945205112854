import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  @Output() pageTitulo: EventEmitter<string> = new EventEmitter<"">();

  messageSent:boolean;



  constructor() { }

  ngOnInit() {

    this.pageTitulo.emit("“Coméntanos sobre tus proyectos o requerimientos, con gusto te apoyaremos”");
  }


  onObjectCreated(statusForm: boolean) {


    this.messageSent=statusForm;
  }


}
