import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/shared/model/model.api';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppHandlerService } from 'src/app/shared/services/services.api';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  resetPwdForm: FormGroup;
  user: User;
  errors: string[] = [];
  isLogin: boolean;

  isLoading: boolean;
  constructor(private fb: FormBuilder,
              private userSrv: UserService,
              private toastr: ToastrService,
              private router: Router,
              private appSrv: AppHandlerService) { }

  ngOnInit() {

    this.isLogin=this.appSrv.isLogin();

    if (this.isLogin){
      this.router.navigate(['not-found']);
    }
    else{
      this.user = new User();
      this.createForm(); 
    }
    
  }

  assignValues(){
    Object.assign(this.user,this.resetPwdForm.value);
  }

  createForm(){
    this.resetPwdForm = this.fb.group(this.user);
    this.resetPwdForm.controls['userName'].setValidators(Validators.required);
    this.resetPwdForm.controls['email'].setValidators([Validators.email,Validators.required,Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}')]);
  }

  resetForm(){    
    this.resetPwdForm.reset(this.user);
    
  }


  resetPwd(){

      //limpiar errores previos
      this.errors = [];

      this.assignValues(); 
      this.isLoading= true;
      this.userSrv.resetPwd(this.user).subscribe((resp:any)=>{
        //console.log(resp);
        
          this.resetForm();
          this.router.navigateByUrl('/msj-password');
        
      }, (err) => {
        console.log(err);
        if(err.status ==400)
          this.toastr.error("Usuario o Correo incorrectos", "Vuelva a Intentarlo")
        else
        this.toastr.error(err.error.message, 'Vuelva al Intentarlo');  
      });
  }

}
