export class Course{
    
    courseId: number;
    name : string;
    subname : string;
    description : string;
    urlImage : string;
    nameInstructor : string;
    descriptionInstructor: string;
    urlPictureProfileInstructor : string;
    enroll: boolean
   

constructor(){

  this.name = null;
  this.subname = null;
  this.description = null;
  this.urlImage = null;
  this.nameInstructor = null;
  this.urlPictureProfileInstructor = null;
  this.descriptionInstructor= null;
  //this.enroll = false;
  
   }

}