import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppHandlerService } from './app.handler.service';
import { CoreService } from './core.service';
//import { Topic } from '../shared/model/Topic.model';
import {  NewsImage } from '../model/model.api';
import { Observable } from 'rxjs';

@Injectable({
    providedIn:'root'
})
export class NewsImageService extends CoreService<NewsImage,number>{
    constructor(http: HttpClient,private appSrv: AppHandlerService){
        super(appSrv.getApiURI() + "NewsImages" ,http);
    }


    getAllbyNews(newsId:string): Observable<NewsImage[]>{
        const params={
          "fknews": newsId
        };
        return this.getAll(null,params);
      }
}