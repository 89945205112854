export class Subtopic{
    codSubTopicId : string;
    name : string;

constructor(){
    this.codSubTopicId = null;
    this.name = null;
  
   }

}