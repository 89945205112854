import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardService } from '../shared/services/dashboard.service';
import { Dashboard, EstadosDashboard } from '../shared/model/model.api';
import { ToastrService } from 'ngx-toastr';
import { AppHandlerService } from '../shared/services/services.api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-portafolio',
  templateUrl: './portafolio.component.html',
  styleUrls: ['./portafolio.component.css']
})
export class PortafolioComponent implements OnInit {
  //@Output() idMenu: EventEmitter<number> = new EventEmitter<0>();

  dashboards: Dashboard[];

  dashWithToken: Dashboard;

  nameDashSel : string;
  imgDashSel: string;
  statusDashSel: string;

  urlDashSel : string;
  isLogin: boolean;

  showDash: boolean;

  showImageDash : boolean;

  urlDashSafe: SafeResourceUrl;

  @Output() idMenu = new EventEmitter<any>();
  constructor(private dashboardSrv:DashboardService,
              private toastr: ToastrService, 
              private appSrv: AppHandlerService,
              public sanitizer: DomSanitizer
              ) {

   }
  

  ngOnInit() {
    this.isLogin=this.appSrv.isLogin();

    this.getDashboards();
  }



  dashSelected(idDash:number){
    
    this.dashboards.forEach(dash => {
      if (dash.dashboardId == idDash){
        this.nameDashSel = dash.nombre;
        this.imgDashSel = dash.urlImage;
        this.statusDashSel = dash.estado;

        if (this.isLogin){
          this.showImageDash = false;
          this.showDash = true;
          this.getDashboardWToken(idDash);
          
        }
        else if (dash.estado == EstadosDashboard.Publico){
          this.showDash = true;
          this.showImageDash = false;
          this.getDashboardPublicWToken(idDash);

        }
        else{
          this.showDash = false;
          this.showImageDash = true;
        }
      }
    });    
  }

  
//trayendo dashboard privado  
getDashboardWToken(id:number) {
  this.showDash=false;
  this.dashboardSrv.getDashIsLogin(id).subscribe(resp=>{
    this.dashWithToken=resp;
    this.urlDashSel = this.dashWithToken.url;
   // this.urlDashSel= "https://tableau.dat-analytics.com/views/Regional/College?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:origin=viz_share_link#2"
    this.urlDashSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.urlDashSel);
    
    //mientras trae el dashboard no mostrar nada
    if (this.urlDashSafe!=null){
      this.showDash=true;
    }
  })
}


getDashboardPublicWToken(id:number) {
  this.showDash=false;
  this.dashboardSrv.getDashPublic(id).subscribe(resp=>{
    this.dashWithToken=resp;
    //console.log(this.dashWithToken);
    this.urlDashSel = this.dashWithToken.url;
   // this.urlDashSel= "https://tableau.dat-analytics.com/views/Regional/College?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:origin=viz_share_link#2"
    this.urlDashSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.urlDashSel);
    
    //mientras trae el dashboard no mostrar nada
    if (this.urlDashSafe!=null){
      this.showDash=true;
    }
  })
}

  getDashboards(){
    this.dashboardSrv.getAll().subscribe(resp=>{
      this.dashboards=resp;
      console.log(this.dashboards);
      //al iniciar -cuando el usuario esta en sesion-
      this.nameDashSel = this.dashboards[0].nombre;
      this.imgDashSel = this.dashboards[0].urlImage;
      this.statusDashSel = this.dashboards[0].estado;
      //this.urlDashSel = this.dashboards[0].url;

      if (this.isLogin==true){
        this.getDashboardWToken(this.dashboards[0].dashboardId);
      }
      else if (this.statusDashSel == EstadosDashboard.Publico)
          {
            this.getDashboardPublicWToken(this.dashboards[0].dashboardId);
            this.showImageDash = false;
            this.showDash = true;
            
          }else{
            this.showImageDash = true;
            this.showDash = false;
          }
   
    })

      
  }

  //Sidebar con fondo azul
  openNav() {
    document.getElementById("mySidenav").style.width = "300px";
     document.getElementById("main").style.marginLeft = "300px";
    document.getElementById("mySidenav").style.left = "0";
    document.getElementById("open").style.display ="none"; 
    // document.getElementById("titlePor").style.display ="none"; 
    document.getElementById("mySidenav").style.display = "block"; 
    if  (this.showImageDash)
      document.getElementById("imgDash").style.width="77%";
    //document.getElementById("contenedor-dash").style.marginTop="50%";
  }


  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft= "0";
    document.getElementById("mySidenav").style.left = "-100px";
    document.getElementById("mySidenav").style.display = "none";
    document.getElementById("open").style.display ="block";
    // document.getElementById("titlePor").style.display ="block"; 

    if  (this.showImageDash)
      document.getElementById("imgDash").style.width="60%";
    //document.getElementById("contenedor-dash").style.marginTop="0";
  }

  updateMnSel(pOpt: number) {
    this.idMenu.emit({menuSelected:pOpt});
    document.getElementById("navbarCanvas-main").style.display="none";

    setTimeout(()=>{    
      this.toastr.info('Inicie sesión o registrese para visualizar e intereactuar con los tableros en vivo. ','',{
        positionClass: 'toast-bottom-left'
      });  
}, 1000);
  }

}
