export class CourseUser{
    
  courseUserId : number;
  codCourseUser : string;
  fkUserId : string;
  fkCourseId : number;
  courseName: string;
  userName: string;
  estado:string;

constructor(){

  //this.courseUserId = null;
  this.codCourseUser = null;
   this.fkUserId = null;
   this.fkCourseId = null;
   this.courseName = null;
   this.userName = null;
   this.estado = null;
  
   }

}