import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dat-premium',
  templateUrl: './dat-premium.component.html',
  styleUrls: ['./dat-premium.component.css']
})
export class DatPremiumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
