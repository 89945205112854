import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppHandlerService } from './app.handler.service';
import { CoreService } from './core.service';
//import { Skill } from '../shared/model/Skill.model';
import { Skill } from '../model/model.api';
import { Observable } from 'rxjs';

@Injectable({
    providedIn:'root'
})
export class SkillService extends CoreService<Skill,number>{
    constructor(http: HttpClient,private appSrv: AppHandlerService){
        super(appSrv.getApiURI() + "Skills" ,http);
    }


    getAllbyCourse(courseId:string): Observable<Skill[]>{
        const params={
          "fkcourse": courseId
        };
        return this.getAll(null,params);
      }
}