import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dat-trends',
  templateUrl: './dat-trends.component.html',
  styleUrls: ['./dat-trends.component.css']
})
export class DatTrendsComponent implements OnInit {

  mnSel: number = 0;

  constructor() { }

  ngOnInit() {
    document.getElementById("logoBlue").style.display = "none";
  }

  // ngAfterViewChecked(){
  //   document.getElementById("logoBlue").style.display = "none";
  // }

  // ngAfterContentChecked(){
    
  // }

  ngDoCheck()
  {
    document.getElementById("logoBlue").style.display = "none";
    console.log("okokok")
  }
  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
   // document.getElementById("navbar-dat-training").style.display="none";
    document.getElementById("navbarCanvas-main").style.display = "none";
  }

}
