import { ToastrService } from 'ngx-toastr';
import { UserService, AppHandlerService } from '../shared/services/services.api';
import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../shared/model/model.api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  user: User;
  userForm: FormGroup;
  errors: string[] = [];

  keyToken: string = 'token';


  constructor(private userSrv: UserService,
              private appSrv: AppHandlerService, 
              private fb: FormBuilder,
              private router: Router, 
              private toastr: ToastrService) { }

  ngOnInit() {
    this.user = new User();
    if (localStorage.getItem(this.keyToken) != null)
      this.router.navigateByUrl('');
      this.createForm();  
  }

  assignValues(){
    Object.assign(this.user,this.userForm.value);
  }

  createForm(){
    this.userForm = this.fb.group(this.user);
    this.userForm.controls['userName'].setValidators(Validators.required);
    this.userForm.controls['password'].setValidators(Validators.required);
  }

  login() {

      //limpiar errores previos
      this.errors = [];

      //asignar valores a nuestro modelo de datos   
      this.assignValues();   

    this.userSrv.login(this.user).subscribe(
      (res: any) => {
        //localStorage.setItem('token', res.token);
        this.appSrv.logIn(res.token);
        // this.router.navigateByUrl('');
        location.reload();
        
      },
      err => {
        if (err.status == 400)
          this.toastr.error('Usuario o Contraseña incorrecta', 'Vuelva a intentarlo');
        else
          this.toastr.error(err.error.message,'Login'); 
      }
    );
    
  }

  getToken(): any {
    return localStorage.getItem(this.keyToken);
  }
}