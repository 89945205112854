export class Skill{
    
    codSkill : string;
    description : string;

   

constructor(){
  this.description = null;
    this.codSkill = null;
  
   }

}