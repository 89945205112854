export class Dashboard{
    
    dashboardId: number;
    nombre : string;
    url : string;
    urlImage: string;
    height : number;
    width : number;
    estado : string;

   

constructor(){

  this.dashboardId = null;
  this.nombre = null;
  this.url = null;
  this.height = null;
  this.width = null;
  this.estado = null;
   }

}