export class BlogSubscription{
    

  BlogSubsId: number;
  email:string;
  status:number;
  FechaCreacion: Date;
  FechaModificacion: Date;
  UsuarioCreacion: string;
  UsuarioModificado: string;
  
  constructor()
  {
    this.BlogSubsId=0;
    this.email=null;
    this.status = 1;
  }
}