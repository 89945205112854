import { Component, OnInit, Output, EventEmitter } from '@angular/core';
//import { WOW } from 'wowjs/dist/wow.min';

@Component({
  selector: 'app-analiticas',
  templateUrl: './analiticas.component.html',
  styleUrls: ['./analiticas.component.css']
})
export class AnaliticasComponent implements OnInit {
  @Output() pageTitulo: EventEmitter<string> = new EventEmitter<"">();

  constructor() { }

  ngOnInit() {
    //new WOW().init();
    this.pageTitulo.emit("“Usted tiene los datos, nosotros hacemos que hablen para usted”");
  }

}
