export class ApplicationForm {

  id: number;
  companyName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  contactedBy: string;
  projectDescription: string;
  availableData: string;
  technologyUsed: string;
  dataArchitectures: string;
  biplatforms: string;
  corporateAdvice: string;
  fechaCreacion: Date;
  scheduleBudget:string;


  constructor() {
    this.id = 0;
    this.companyName = null;
    this.fullName = null;
    this.email = null;
    this.phoneNumber = null;
    this.contactedBy = null;
    this.projectDescription = null;
    this.availableData = null;
    this.technologyUsed = null;
    this.dataArchitectures = null;
    this.biplatforms = null;
    this.corporateAdvice = null;
    this.fechaCreacion = null;
    this.scheduleBudget = null;

  }

}