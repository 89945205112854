import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppHandlerService } from 'src/app/shared/services/services.api';

@Component({
  selector: 'app-navbar-dat-training',
  templateUrl: './navbar-dat-training.component.html',
  styleUrls: ['./navbar-dat-training.component.css']
})
export class NavbarDatTrainingComponent implements OnInit {
  
  constructor(private appSrv: AppHandlerService) { }
  isLogin : boolean;

  userName:string ="Session";
  fullName:string ="Session";
  urlProfilePicture:string="";

  @Output() idMenu = new EventEmitter<any>();
  ngOnInit() {

    this.isLogin = this.appSrv.isLogin();

    if (this.isLogin) {
      this.fullName = this.appSrv.getCurrentUser().fullName;
      this.userName = this.appSrv.getCurrentUser().userName;
      this.urlProfilePicture = this.appSrv.getCurrentUser().urlProfilePicture;
    }
      
  }



  onLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    //this.router.navigate(['']);
    location.reload();
    //console.log("sesion cerrada...");
  }

  updateMnSel(pOpt: number) {
    //this.idMenu.emit(pOpt);
      this.idMenu.emit({menuSelected:pOpt});
    
      
  }

}
