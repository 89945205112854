import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppHandlerService } from './app.handler.service';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';

import { ApplicationForm } from '../model/applicationform.model';

@Injectable({
    providedIn:'root'
})
export class ApplicationFormService extends CoreService<ApplicationForm,number>{
    constructor(http: HttpClient,private appSrv: AppHandlerService){
        super(appSrv.getApiURI() + "ApplicationsForm" ,http);
    }

    send(obj:ApplicationForm): Observable<ApplicationForm>{

        return this.http.post<ApplicationForm>(this.base+"/send", obj);
        
      }
}