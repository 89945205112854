import { Injectable } from '@angular/core';
import { Blog } from '../model/blog.model';
import { AppHandlerService } from './app.handler.service';
import { HttpClient } from '@angular/common/http';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { BlogSubscription } from '../model/model.api';


@Injectable({
  providedIn: 'root'
})
export class BlogService extends CoreService<Blog, number>{

  constructor( http:HttpClient, private appSrv: AppHandlerService ) 
  {
    super(appSrv.getApiURI() + "blogpost", http);
  }


  getBlogAll(): Observable<Blog[]>
  {
    return this.http.get<Blog[]>(this.base);
  }


  getPublishedPosts(): Observable<Blog[]>
  {
    return this.http.get<Blog[]>(this.base+"/published-posts");
  }


  getBlogbyId(id: string): Observable<Blog[]>
  {
    return this.http.get<Blog[]>(this.base+"/"+id);
  }

  createBlog(obj:any): Observable<Blog[]>{

   
    return this.http.post<Blog[]>(this.base, obj);
    
  }

  createBlogSubscription(obj:any): Observable<BlogSubscription[]>{

    

    return this.http.post<BlogSubscription[]>(this.base+"/createSubscription/", obj);
    
  }

}
