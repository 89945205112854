import { Component, OnInit } from '@angular/core';
import { AppHandlerService, CourseService, CourseUserService } from 'src/app/shared/services/services.api';
import { Course, CourseUser } from 'src/app/shared/model/model.api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.css']
})
export class MyCoursesComponent implements OnInit {

  idUser : string;
  coursesEnrolled: Course [];

  courses: Course[];

  isLogin: boolean;
  flagCourses: boolean
  courseUser : CourseUser
  mnSel : number=0;
  constructor( private appSrv : AppHandlerService,
      
                private courseUserSrv: CourseUserService,
                private router: Router) { }

  ngOnInit() {


    this.isLogin=this.appSrv.isLogin();
    
    if(this.isLogin){
      //this.userName = this.appSrv.getCurrentUser().userName;
      this.idUser = this.appSrv.getCurrentUser().id;
      this.getEnrolledCourses();
      
    }
    else{
      this.router.navigate(['']);

    }

      

  }

  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
    document.getElementById("navbar-dat-training").style.display="none";
  }


  getEnrolledCourses(){

      this.courseUserSrv.getEnrolledCourses(this.idUser).subscribe(resp=>{
        this.coursesEnrolled = resp;

       //depende si hay o no cursos agregados
        this.flagCourses=Object.keys(this.coursesEnrolled).length === 0
       
       
      });
    
  }

  unEnroll(courseId:number){

    this.courseUser = new CourseUser();

    this.courseUser.fkUserId = this.appSrv.getCurrentUser().id;
    this.courseUser.fkCourseId = courseId;

    this.courseUserSrv.unEnroll(this.courseUser).subscribe(resp=>{
      this.getEnrolledCourses();
    });
  }
}


