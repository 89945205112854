import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent  {
  @Output() public idMenu = new EventEmitter<any>();
  //@Output() idMenu: EventEmitter<number> = new EventEmitter<0>();

  Opcion1:boolean=true;
  Opcion2:boolean;
  Opcion3:boolean;
  Opcion4:boolean;

  constructor() { }
   updateMnSel(pOpt: number) {
    //this.idMenu.emit(pOpt);
    this.idMenu.emit({menuSelected:pOpt});
  }

  ngOnInit() {
  }

showOpcion1(){
  this.Opcion1=true;
  this.Opcion2=false;
  this.Opcion3=false;
  this.Opcion4=false;
}

showOpcion2(){
  this.Opcion1=false;
  this.Opcion2=true;
  this.Opcion3=false;
  this.Opcion4=false;
}

showOpcion3(){
  this.Opcion1=false;
  this.Opcion2=false;
  this.Opcion3=true;
  this.Opcion4=false;
}

showOpcion4(){
  this.Opcion1=false;
  this.Opcion2=false;
  this.Opcion3=false;
  this.Opcion4=true;
}

}
