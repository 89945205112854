export class NewsImage {

    codNewsImage: string;
    title: string;
    description: string;
    fkNewsId: number;
    urlImage: string;
    usuarioCreacion: string;
    fechaCreacion: Date;


    constructor() {

        this.codNewsImage = null;
        this.title = null;
        this.description = null;
        this.fkNewsId = null;
        this.urlImage = null;
        this.usuarioCreacion = null;
        this.fechaCreacion = null;


    }

}
