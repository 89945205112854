import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../shared/model/user.model';
import { AppHandlerService,UserService } from '../shared/services/services.api';


@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.css']
})
export class TopNavBarComponent implements OnInit {
  // @Output() idMenu: EventEmitter<number> = new EventEmitter<0>();
  @Output() idMenu = new EventEmitter<any>();
  
  userDetails

  userName= "Session"
  isLogin : boolean;

  perfilUsuario: User[];

  constructor(private router: Router,
              private service: UserService,
              private appSrv: AppHandlerService) { }

  ngOnInit() {
      
    document.getElementById("logoBlue").style.display = "none";
    this.isLogin=this.appSrv.isLogin();

    if(this.isLogin)
      this.userName = this.appSrv.getCurrentUser().fullName;

      //var prevScrollpos = window.pageYOffset;0

      // window.onscroll=function(){
      //   var currentScrollpos = window.pageYOffset;0
      //   if (prevScrollpos > currentScrollpos){
      //     document.getElementById("navbar2").style.top="0";
      //   }else{
      //     document.getElementById("navbar2").style.top="-200px";
      //   }
      //   prevScrollpos=currentScrollpos;

      //   if (window.pageYOffset<=0){
      //     document.getElementById("navbar2").style.opacity="0";
      //     document.getElementById("navbar2").style.top="-200px";
      //   }
      //   else
      //   {
      //     document.getElementById("navbar2").style.opacity="1";

      //   }
      // }

  }

  updateMnSel(pOpt: number) {
    // this.idMenu.emit(pOpt);
    this.idMenu.emit({menuSelected:pOpt});
  }

  onLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    //this.router.navigate(['']);
    location.reload();
  }
  
//detectando scroll solo para este componente
// @HostListener('window:scroll', ['$event']) // for window scroll events
// onScroll($event: Event) {
//   var prevScrollpos = window.pageYOffset; 0

//   var navbar = document.getElementById("navbar-main");
//   var btnAcceder = document.getElementById("btnAcceder-main");
//   var btnAccederMobile = document.getElementById("btnAcceder-main-mobile");
//   var nosotros = document.getElementById("nosotros-nav");
//   var servicio = document.getElementById("servicio-nav");
//   var trainningNav = document.getElementById("trainning-nav");
//   var pensamientoNav = document.getElementById("pensamiento-nav");
//   var servicedeskNav = document.getElementById("servicedesk-nav");
//   var contactoNav = document.getElementById("contacto-nav");
//   var burguerMenuNav = document.getElementById("burguerMenu-nav");
//   var btnLoginNav = document.getElementById("btnLogin-nav");

//   var currentScrollpos = window.pageYOffset; 0
//   prevScrollpos = currentScrollpos;

//   if (window.pageYOffset == 0) {

//     navbar.classList.add("nav-main-section");
//     navbar.classList.remove("nav-main-section-white");


//     nosotros.classList.add("opcion-navbar-main");
//     nosotros.classList.remove("opcion-navbar-main-dark");

//     servicio.classList.add("opcion-navbar-main");
//     servicio.classList.remove("opcion-navbar-main-dark");

//     trainningNav.classList.add("opcion-navbar-main");
//     trainningNav.classList.remove("opcion-navbar-main-dark");

//     pensamientoNav.classList.add("opcion-navbar-main");
//     pensamientoNav.classList.remove("opcion-navbar-main-dark");

//     servicedeskNav.classList.add("opcion-navbar-main");
//     servicedeskNav.classList.remove("opcion-navbar-main-dark");

//     contactoNav.classList.add("opcion-navbar-main");
//     contactoNav.classList.remove("opcion-navbar-main-dark");

//     burguerMenuNav.classList.add("burguerMenuNav");
//     burguerMenuNav.classList.remove("burguerMenuNav-blue");



//     document.getElementById("logoWhite").style.display = "block";
//     document.getElementById("logoBlue").style.display = "none";

//     //si el usuario esta en session 
//     if (btnAcceder != null && btnAccederMobile != null) {
//       btnAcceder.classList.add("btn-primaryDat-white");
//       btnAcceder.classList.remove("btn-primaryDat-blue");

//       btnAccederMobile.classList.add("btn-primaryDat-white");
//       btnAccederMobile.classList.remove("btn-primaryDat-blue");
//     }

//     if (btnLoginNav != null) {
//       btnLoginNav.classList.add("btnLogin");
//       btnLoginNav.classList.remove("btnLogin-blue");
//     }




//   }
//   else if (window.pageYOffset > 50) {
//     navbar.classList.remove("nav-main-section");
//     navbar.classList.add("nav-main-section-white");

//     nosotros.classList.remove("opcion-navbar-main");
//     nosotros.classList.add("opcion-navbar-main-dark");

//     servicio.classList.remove("opcion-navbar-main");
//     servicio.classList.add("opcion-navbar-main-dark");

//     trainningNav.classList.remove("opcion-navbar-main");
//     trainningNav.classList.add("opcion-navbar-main-dark");

//     pensamientoNav.classList.remove("opcion-navbar-main");
//     pensamientoNav.classList.add("opcion-navbar-main-dark");

//     contactoNav.classList.remove("opcion-navbar-main");
//     contactoNav.classList.add("opcion-navbar-main-dark");

//     servicedeskNav.classList.remove("opcion-navbar-main");
//     servicedeskNav.classList.add("opcion-navbar-main-dark");

//     burguerMenuNav.classList.remove("burguerMenuNav");
//     burguerMenuNav.classList.add("burguerMenuNav-blue");



//     document.getElementById("logoWhite").style.display = "none";
//     document.getElementById("logoBlue").style.display = "block";

//     //si el usuario esta en session 
//     if (btnAcceder != null && btnAccederMobile != null) {

//       btnAcceder.classList.remove("btn-primaryDat-white");
//       btnAcceder.classList.add("btn-primaryDat-blue");

//       btnAccederMobile.classList.remove("btn-primaryDat-white");
//       btnAccederMobile.classList.add("btn-primaryDat-blue");
//     }

//     if (btnLoginNav != null) {
//       btnLoginNav.classList.remove("btnLogin");
//       btnLoginNav.classList.add("btnLogin-blue");
//     }
//   }




// }


}
