import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppHandlerService } from './app.handler.service';
import { CoreService } from './core.service';

import { CourseUser, Course } from '../model/model.api';
import { Observable } from 'rxjs';

@Injectable({
    providedIn:'root'
})
export class CourseUserService extends CoreService<CourseUser,number>{
    constructor(http: HttpClient,private appSrv: AppHandlerService){
        super(appSrv.getApiURI() + "CourseUsers" ,http);
    }

    unEnroll(formData) {
        return this.http.post(this.base + '/unEnroll', formData);
      }


       //traer cursos cuando el usuario este logueado y verificando que cursos tiene inscritos
       getEnrolledCourses(id: string): Observable<Course[]> {
        
        return this.http.get<Course[]>(this.base + "/" + id + "/enrolledCourses");
      }
}