import { Component, OnInit } from '@angular/core';
import { Ad } from '../model/model.api';
import { AdService } from '../services/services.api';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.css']
})
export class AdsComponent implements OnInit {

  ads: Ad[] = [];
  responsiveOptions;

  circular: boolean = true;

  constructor(private adSrv: AdService) {

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit() {

    this.getAd();

  }

  getAd() {
    this.adSrv.getAds().subscribe(resp => {
      this.ads = resp;
      // console.log(this.ads);
       //console.log(this.ads.length)
      if (this.ads.length > 1) {
        this.circular = true;
      }
      else{
        this.circular=false;
      }
    })
  }

}
