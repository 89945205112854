import { UserService } from '../shared/services/user.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../shared/model/user.model';
import { SelectItem } from 'primeng/api';
import { Sexo } from '../shared/model/constantes';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  selectedDay: string = '';

  user:User;
  registrationForm: FormGroup;

  rangos:any[];
  areas:any[];
  cargos:any[];
  sexos: SelectItem[];
  constructor(public userSrv: UserService, 
              private toastr: ToastrService,
              private fb: FormBuilder, 
              private router: Router) { }
 
  ngOnInit() {
    this.user = new User();
    this.loadDropdown();
    this.resetForm();
    this.userSrv.formModel.reset();
    this.rangos=[
      {codigo:"(0-50)",nombre: "(0-50)"},
      {codigo: "(50-100)", nombre:"(50-100)"},
      {codigo:"(100+)", nombre:"(100+)"}
    ];

    this.areas=[
      {codigo:"Administración", nombre: "Administración"},
      {codigo:"Administración de productos",nombre:"Administración de productos"},
      {codigo: "Análisis", nombre:"Análisis"},
      {codigo: "Compras", nombre: "Compras"},
      {codigo: "Fabricación", nombre: "Fabricación"},
      {codigo: "Finanzas", nombre: "Finanzas"},
      {codigo: "Ingeniería/I+D", nombre:"Ingeniería/I+D"},
      {codigo: "Legal", nombre:"Legal"},
      {codigo: "Marketing", nombre: "Marketing"},
      {codigo: "Recursos humanos",nombre:"Recursos humanos"},
      {codigo: "Socio de canal", nombre: "Socio de canal"},
      {codigo: "Soporte/servicio", nombre:"Soporte/servicio"},
      {codigo: "TI", nombre:"TI"},
      {codigo:"Ventas"}
    ];

    this.cargos=[
      {codigo:"Analista", nombre:"Analista"},
      {codigo:"Arquitecto", nombre:"Arquitecto"},
      {codigo:"CEO/presidente", nombre:"CEO/presidente"},
      {codigo:"Consultor/integrador de sistemas", nombre:"Consultor/integrador de sistemas"},
      {codigo:"Coordinador/especialista",nombre:"Coordinador/especialista"},
      {codigo:"Decano/rector",nombre:"Decano/rector"},
      {codigo:"Desarrollador/ingeniero",nombre:"Desarrollador/ingeniero"},
      {codigo:"Director",nombre:"Director"},
      {codigo:"Estudiante",nombre:"Estudiante"},
      {codigo:"Nivel directivo",nombre:"Nivel directivo"},
      {codigo:"Profesor",nombre:"Profesor"},
      {codigo:"Responsable",nombre:"Responsable"},
      {codigo:"Vicepresidente",nombre:"Vicepresidente"}

    ]
    //console.log(this.rangos);  
   // this.createForm();
  }

  createForm(){
    //this.userSrv.formModel = this.fb.group(this.user);
    //this.userSrv.formModel.controls['Email'].setValidators([Validators.email,Validators.required,Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}')]); 
    //this.userSrv.formModel.controls['FullName'].setValidators(Validators.required);

  }

  loadDropdown() {

    //sexos
    this.sexos = [];
    this.sexos.push({ label: "", value: null });
    this.sexos.push({ label: "Femenino", value: Sexo.Femenino });
    this.sexos.push({ label: "Masculino", value: Sexo.Masculino });
    this.sexos.push({ label: "Otro", value: null });
  }

  resetForm(){    
    this.userSrv.formModel.reset(this.user);
    //this.historialIncidenciaForm.reset(this.historialIncidencia);
    
  }
  onSubmit() {
      //  console.log(this.service.formModel);

      //   return;

    //console.log(this.userSrv.formModel);
    
    this.userSrv.register().subscribe(

      (res: any) => {
        if (res.succeeded) {
          this.userSrv.formModel.reset();
          setTimeout(()=>{    
                  this.toastr.info('Creacion de Nuevo Usuario','Registro exitoso',{
                    positionClass: 'toast-top-center'
                  });  
            }, 500);
          
          location.reload();
        } else {
          res.errors.forEach(element => {
            switch (element.code) {
              case 'DuplicateUserName':
                setTimeout(()=>{    
                  this.toastr.error('Nombre de Usuario no disponible','Registro Fallido',{
                    positionClass: 'toast-top-center'
                  });  
            }, 500);
          
                break;

              default:
              this.toastr.error(element.description,'Registro fallido');
                break;
            }
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

}