import { Component, OnInit } from '@angular/core';
import { AppHandlerService, UserService } from 'src/app/shared/services/services.api';
import { User } from 'src/app/shared/model/model.api';
import { Router } from '@angular/router';
import { Sexo } from 'src/app/shared/model/constantes';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  fullName: string
  userName: string
  urlProfilePicture:string;
  email : string;
  telefono : string;
  mnSel: number = 0;
  userProfile : User;
  isLogin: boolean;
  fechaNacimiento:Date;
  genero:string;
  constructor(
    private appSrv: AppHandlerService,
    private userProfileSrv : UserService,
    private router: Router
  ) { }

  ngOnInit() {

    this.isLogin=this.appSrv.isLogin();
    
    if(this.isLogin){
      this.userProfileSrv.getById(this.appSrv.getCurrentUser().id).subscribe(resp => {
        this.userProfile = resp;
        //console.log(this.userProfile);
        this.userName = this.userProfile.userName;
      this.fullName = this.userProfile.fullName;
      this.email = this.userProfile.email;
      this.urlProfilePicture=this.userProfile.urlProfilePicture;
      this.telefono=this.userProfile.telefono;
      this.fechaNacimiento= this.userProfile.fechaNacimiento;
      this.genero=this.userProfile.sexo;

      if (this.userProfile.sexo==Sexo.Femenino){
        this.genero="Femenino";
      }
      else if (this.userProfile.sexo==Sexo.Masculino){
        this.genero="Masculino";
      }
       
      });
    }  
    else{
      this.router.navigate(['']);
    }

  }

  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
    document.getElementById("navbar-dat-training").style.display="none";
  }
}
