import { Component, OnInit, Input,HostListener } from '@angular/core';
import { delay } from 'q';
//import { IDashList } from '../listdash/i-dash-list';
import { Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';

//import { WOW } from 'wowjs/dist/wow.min';

//import AOS from 'aos';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userDetails;
  mnSel: number = 0;
  //newsSelHome:number=0;
  //@Input() dhSel: IDashList;
  

  loading: boolean = true;

  constructor(private router: Router) { }

  ngOnInit() {
    //new WOW().init();
    //AOS.init();

    setTimeout(() => {
      //console.log("Hasta el bollo")
      this.loading = false;
    }, 1000);

  }

  //asignando menu y noticia seleccionada
  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
    //this.newsSelHome= event.NewsSelected;
    document.getElementById("navbarCanvas-main").style.display="none";
  }





  // UpdateDhSel(event: IDashList) {
  //   this.dhSel = event;
  // }

  // onScroll($event: Event) {
  // }
}
