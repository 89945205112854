import { Component, Output, EventEmitter, HostListener, Renderer2 } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { AppHandlerService } from '../shared/services/services.api';
import { ActivatedRoute } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { BlogService } from '../shared/services/blog.service';
import { Blog } from '../shared/model/blog.model';
import { BlogSubscription } from '../shared/model/model.api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-main-section',
  templateUrl: './main-section.component.html',
  styleUrls: ['./main-section.component.css']
})
export class MainSectionComponent {
  urlVideo: string[] = ["assets/video/dat_intro.mp4"];
  //@Output() idMenu: EventEmitter<number> = new EventEmitter<0>();
  @Output() public idMenu = new EventEmitter<any>();
  userName = "Session";
  fullName = "Session";
  urlProfilePicture = ""
  isLogin: boolean;
  mnSel: number = 0;
  blog: Blog[];
  errors: string[] = [];

  blogPublished: Blog[];
  waitingResponse: boolean;
  blogSubscription: BlogSubscription;
  blogSubscriptionForm: FormGroup;
  constructor(private sanitizer: DomSanitizer,
    private appSrv: AppHandlerService,
    private render: Renderer2,
    private blogSrv:BlogService,
    private fb: FormBuilder,
    private toastr: ToastrService,) { }

  ngOnInit() {

    document.getElementById("logoBlue").style.display = "none";

    this.blogSubscription = new BlogSubscription();
    
    this.createForm();

    this.isLogin = this.appSrv.isLogin();

    
    if (this.isLogin) {
      this.fullName = this.appSrv.getCurrentUser().fullName;
      this.userName = this.appSrv.getCurrentUser().userName;
      this.urlProfilePicture = this.appSrv.getCurrentUser().urlProfilePicture;
    }

    this.getBlog();

  }

  ngAfterViewChecked(){
    document.getElementById("logoBlue").style.display = "none";
  }

  updateMnSel(pOpt: number) {
    //this.idMenu.emit(pOpt);
    this.idMenu.emit({ menuSelected: pOpt });
    //document.getElementById("navbarCanvas-main").style.display = "none";
  }

  UpdateMnSel2(event) {
    this.mnSel = event.menuSelected;
    document.getElementById("navbar-dat-training").style.display = "none";
    document.getElementById("navbarCanvas-main").style.display = "none";
  }


  getVideoTag(file) {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<video id="daIntro" autoplay muted onloadedmetadata="this.muted = true" loop poster="assets/img/Intro.png" style="position: absolute !important;top: 0 !important;left: 0 !important;min-width: 2000px !important;min-height: 1000px; !important;height: auto;width : auto !important;z-index: -100 !important;opacity:0.6 !important;">
          <source src="${file}" type="video/mp4">Su explorador no soporta video HTML5.</source>
       </video>`
    );
  }

  onLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    //this.router.navigate(['']);
    location.reload();
    //console.log("sesion cerrada...");
  }

  //detectando scroll solo para este componente
  @HostListener('window:scroll', ['$event']) //
  onScroll($event: Event) {
    var prevScrollpos = window.pageYOffset; 0

    var navbar = document.getElementById("navbar-main");
    var btnAccederMobile = document.getElementById("btnAcceder-main-mobile");
    var nosotros = document.getElementById("nosotros-nav");
    var servicio = document.getElementById("servicio-nav");
    var trainningNav = document.getElementById("trainning-nav");
    var pensamientoNav = document.getElementById("pensamiento-nav");
    var servicedeskNav = document.getElementById("servicedesk-nav");
    var contactoNav = document.getElementById("contacto-nav");
    var burguerMenuNav = document.getElementById("burguerMenu-nav");
    var btnLoginNav = document.getElementById("btnLogin-nav");

    var currentScrollpos = window.pageYOffset; 0
    prevScrollpos = currentScrollpos;

    if (window.pageYOffset == 0) {

      // navbar.classList.add("nav-main-section");
      // navbar.classList.remove("nav-main-section-white");


      // nosotros.classList.add("opcion-navbar-main");
      // nosotros.classList.remove("opcion-navbar-main-dark");

      // servicio.classList.add("opcion-navbar-main");
      // servicio.classList.remove("opcion-navbar-main-dark");

      // trainningNav.classList.add("opcion-navbar-main");
      // trainningNav.classList.remove("opcion-navbar-main-dark");

      // pensamientoNav.classList.add("opcion-navbar-main");
      // pensamientoNav.classList.remove("opcion-navbar-main-dark");

      // servicedeskNav.classList.add("opcion-navbar-main");
      // servicedeskNav.classList.remove("opcion-navbar-main-dark");

      // contactoNav.classList.add("opcion-navbar-main");
      // contactoNav.classList.remove("opcion-navbar-main-dark");

      // burguerMenuNav.classList.add("burguerMenuNav");
      // burguerMenuNav.classList.remove("burguerMenuNav-blue");



      // document.getElementById("logoWhite").style.display = "block";
      // document.getElementById("logoBlue").style.display = "none";
      // if (btnLoginNav != null) {
      //   btnLoginNav.classList.add("btnLogin");
      //   btnLoginNav.classList.remove("btnLogin-blue");
      // }
    }
    else if (window.pageYOffset > 50) {
      // navbar.classList.remove("nav-main-section");
      // navbar.classList.add("nav-main-section-white");

      // nosotros.classList.remove("opcion-navbar-main");
      // nosotros.classList.add("opcion-navbar-main-dark");

      // servicio.classList.remove("opcion-navbar-main");
      // servicio.classList.add("opcion-navbar-main-dark");

      // trainningNav.classList.remove("opcion-navbar-main");
      // trainningNav.classList.add("opcion-navbar-main-dark");

      // pensamientoNav.classList.remove("opcion-navbar-main");
      // pensamientoNav.classList.add("opcion-navbar-main-dark");

      // contactoNav.classList.remove("opcion-navbar-main");
      // contactoNav.classList.add("opcion-navbar-main-dark");

      // servicedeskNav.classList.remove("opcion-navbar-main");
      // servicedeskNav.classList.add("opcion-navbar-main-dark");

      // burguerMenuNav.classList.remove("burguerMenuNav");
      // burguerMenuNav.classList.add("burguerMenuNav-blue");

      // document.getElementById("logoWhite").style.display = "none";
      // document.getElementById("logoBlue").style.display = "block";
      // if (btnLoginNav != null) {
      //   btnLoginNav.classList.remove("btnLogin");
      //   btnLoginNav.classList.add("btnLogin-blue");
      // }
    }




  }



  getBlog()
  {
    //this.loading = true;

    // info del post
    this.blogSrv.getPublishedPosts().subscribe(result => {
       this.blog=result;
       //console.log(this.blog)
    },(err) => {
      console.log(err);
    });

  }


  createForm() {
    this.blogSubscriptionForm = this.fb.group(this.blogSubscription);
    this.blogSubscriptionForm.controls['email'].setValidators([Validators.email, Validators.required, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}')]);
    //this.addSubscriptionForm.controls['description'].setValidators(Validators.required);
  }

  add() {
    this.errors = [];
    this.blogSubscription = new BlogSubscription();
    //this.titulo = "Nuevo Anuncio";
    this.resetForm();
    //this.showDialog();
  }

  resetForm() {
    this.blogSubscriptionForm.reset(this.blogSubscription);
    this.blogSubscriptionForm.controls['email'].setValue(" ");
  }

  isNew(): boolean {

    return this.blogSubscription.BlogSubsId != 0;
  }

  assignValues() {

    Object.assign(this.blogSubscription, this.blogSubscriptionForm.value);
    this.blogSubscription.UsuarioCreacion="Dat-Analytics";


   
  }

  saveSubscription() {

    this.waitingResponse = true;
    //limpiar errores previos
    this.errors = [];
    console.log("llegando")
    //asignar valores a nuestro modelo de datos   
    this.assignValues();

    //console.log(this.ad)

    //salvar
    if (this.blogSubscription.BlogSubsId == 0) {

      this.blogSrv.createBlogSubscription(this.blogSubscription).subscribe(resp => {
        //this.objectCreated.emit(resp);
        setTimeout(()=>{    
          this.toastr.info(' Ahora recibiras todas nuestras novedades','Suscripcion activa',{
            positionClass: 'toast-top-center'
          });  
          this.resetForm();
    }, 500);
   
        this.waitingResponse = false;
      }, (err) => {

        this.waitingResponse = false;
        if (err.status == 400) {

          setTimeout(()=>{    
            this.toastr.error(' ' + err.error,'Correo en uso',{
              positionClass: 'toast-top-center'
            });  
      }, 500);

      console.log(err)
    
          
        }
        else{
          this.errors = this.appSrv.getErrosMessages(err);
          
          //console.log(this.errors);
        }
        
        
      });
    } else {
      // this.adSrv.update(this.ad.idad, this.ad).subscribe(resp => {
      //   this.objectUpdated.emit(resp);
      //   this.waitingResponse = false;
      // }, (err) => {
      //   this.errors = this.appSrv.getErrosMessages(err);
      //   this.waitingResponse = false;
      // });
      console.log("No se puede actualizar la suscripcion");
    }
  }
}
