import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Course } from '../../shared/model/course.model';
import { CourseService, AppHandlerService, CourseUserService } from 'src/app/shared/services/services.api';
import { ToastrService } from 'ngx-toastr';
import { CourseUser } from 'src/app/shared/model/model.api';

@Component({
  selector: 'app-courses-dat-training',
  templateUrl: './courses-dat-training.component.html',
  styleUrls: ['./courses-dat-training.component.css']
})
export class CoursesDatTrainingComponent implements OnInit {

  courses: Course[];

  coursesLogin: Course[];
  smsCourses:string;

  isEnroll :boolean;
  isLogin:boolean;
  userName:string;

  courseUser : CourseUser;

  @Output() idMenu = new EventEmitter<any>();

  constructor(private courseSrv:CourseService,
              private appSrv: AppHandlerService,
              private toastr: ToastrService, 
              private courseUserSrv: CourseUserService) { }

  ngOnInit() {
    
    this.isLogin=this.appSrv.isLogin();
    

    if(this.isLogin){
      this.userName = this.appSrv.getCurrentUser().userName;
      
    }
    
    this.getCourses();
}



  onEnroll(courseId:number){

    this.courseUser = new CourseUser();

    this.courseUser.fkUserId = this.appSrv.getCurrentUser().id;
    this.courseUser.fkCourseId = courseId;
    this.courseUser.estado = "No Autorizado"

    this.courseUserSrv.create(this.courseUser).subscribe((resp)=>{
        this.courseUser = resp;

       // console.log("El id del curso retornado: "+ this.courseUser.fkCourseId + " El usuario retornado es: "+ this.courseUser.fkUserId);
    });

    //agregando
    for (var _i = 0; _i < this.courses.length; _i++) {
        
      if (courseId == this.courses[_i].courseId)
            this.courses[_i].enroll=true;

  }

  }

  unEnroll(courseId:number){

    this.courseUser = new CourseUser();

    this.courseUser.fkUserId = this.appSrv.getCurrentUser().id;
    this.courseUser.fkCourseId = courseId;
    
      //agregando
      for (var _i = 0; _i < this.courses.length; _i++) {
        
        if (courseId == this.courses[_i].courseId)
              this.courses[_i].enroll=false;
    }

    this.courseUserSrv.unEnroll(this.courseUser).subscribe(resp=>{
      //console.log(resp);
    });

  }





  updateMnSel(pOpt: number, curso:string) {
    //this.idMenu.emit(pOpt);
      this.idMenu.emit({menuSelected:pOpt});
      
      setTimeout(()=>{    
        this.toastr.info('Inicie sesion o registrese para inscribir curso '+curso,'',{
          positionClass: 'toast-bottom-left'
        });  
  }, 1000);
      
  }



    getCourses(){

      this.courseUser = new CourseUser();
      
      if (this.isLogin)
      {
        this.courseSrv.getCoursesIsLogin(this.appSrv.getCurrentUser().id).subscribe(resp=>{
          this.courses= resp;
        });
      }

      else{
        this.courseSrv.getAll().subscribe(resp=>{
          this.courses = resp;

          if (Object.keys(resp).length === 0)
        {
          this.smsCourses= "No hay cursos que mostrar";
          this.courses = null;
        }

        else{
              this.courses = resp;
              this.smsCourses= null;
        }

        })
      }
        
    }
  }
