import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
//import { IDashList } from '../listdash/i-dash-list';
import { delay } from 'q';

@Component({
  selector: 'app-dat-training',
  templateUrl: './dat-training.component.html',
  styleUrls: ['./dat-training.component.css']
})


export class DatTrainingComponent implements OnInit {
  constructor() { }
 
  userDetails;
  mnSel: number = 0;
  //@Input() dhSel: IDashList;
  ngOnInit() {
    
   
    
   
  }

  //desplazarse a la parte superior al acceder a una ruta
  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
}

  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
    document.getElementById("navbar-dat-training").style.display="none";
  }
  

  // UpdateDhSel(event: IDashList) {
  //   this.dhSel = event;
  // }

}
