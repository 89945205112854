import { Component, OnInit, Output, EventEmitter } from '@angular/core';

interface Tools {
  Nombre: string,
  Logo: string
}
@Component({
  selector: 'app-tecnologia-utilizada',
  templateUrl: './tecnologia-utilizada.component.html',
  styleUrls: ['./tecnologia-utilizada.component.css']
})
export class TecnologiaUtilizadaComponent implements OnInit {
  @Output() pageTitulo: EventEmitter<string> = new EventEmitter<"">();
  _baseImgUrl: string = 'assets/img/Tools/';

  grupo1: Tools[] = [
    { Nombre: 'Tableau', Logo: this._baseImgUrl + 'tableau_logo.png' },
    { Nombre: 'PowerBI', Logo: this._baseImgUrl + 'POWERBI.png' },
    { Nombre: 'Qlik Sense', Logo: this._baseImgUrl + 'qlik_sense_logo.png' },
    { Nombre: 'Excel', Logo: this._baseImgUrl + 'excel_logo.png'}
  ]

  grupo2: Tools[] = [
    { Nombre: 'R', Logo: this._baseImgUrl + 'r_logo.png'},
    { Nombre: 'RapidMiner', Logo: this._baseImgUrl + 'rapidminer_logo.png'},
    { Nombre: 'Sas', Logo: this._baseImgUrl + 'sas_logo.png'},
    { Nombre: 'Python', Logo: this._baseImgUrl + 'python_logo.png'}
  ]

  grupo3: Tools[] = [
    { Nombre: 'SQL Server', Logo: this._baseImgUrl + 'sqlserver_logo.png' },
    { Nombre: 'MySQL', Logo: this._baseImgUrl + 'mysql_logo.png' },
    { Nombre: 'NodeJs', Logo: this._baseImgUrl + 'nodejs_logo.png' },
    { Nombre: 'Angular', Logo: this._baseImgUrl + 'angular-icon.png' },
    { Nombre: '.Net', Logo: this._baseImgUrl + 'net_logo.png' },
    { Nombre: 'PostgreSQL', Logo: this._baseImgUrl + 'postgresql_logo.png' }
  ]

  grupo4: Tools[] = [
    { Nombre: 'AWS ', Logo: this._baseImgUrl + 'awslogo.png' },
    { Nombre: 'Microsoft Azure', Logo: this._baseImgUrl + 'MS-Azure-Logo.webp' },
  ]
  constructor() { }

  ngOnInit() {
    this.pageTitulo.emit("“Dominamos muchas de las top herramientas del momento, entre ellas:”");
  }

}
