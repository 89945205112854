import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
//import { IDashList } from 'src/app/listdash/i-dash-list';
import { delay } from 'q';
import { ActivatedRoute } from '@angular/router';
import { CourseService, SkillService, TopicService, AppHandlerService } from 'src/app/shared/services/services.api';
import { Course, Skill,Topic, Subtopic, CourseUser } from 'src/app/shared/model/model.api';
import { SubtopicService } from 'src/app/shared/services/subtopic.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {

  constructor(private _route: ActivatedRoute,
              private courseSrv: CourseService,
              private skillSrv: SkillService,
              private topicSrv: TopicService,
              private appSrv: AppHandlerService 
                
              ) { }
  courseId : number=0;
  topicId: number=0;
  fkCourse: string;
  
  course: Course;

  courseUser : CourseUser;

  skills: Skill [];
  topics: Topic[];
  subtopics: Subtopic[];
  userDetails;
  mnSel: number = 0;

   values = {name: 'misko', gender: 'male'};
  
  msgSkills:string;
  msgTopics:string;
  msgSubtopics:string;

  isLogin:boolean;
  //@Input() dhSel: IDashList;
  @Output() idMenu = new EventEmitter<any>();

  ngOnInit() {

    //capturando el parametro pasado por url 
    this.courseId = +this._route.snapshot.paramMap.get('id');
  
   this.isLogin=this.appSrv.isLogin();
    this.getCourse();
    this.getSkills();
    this.getTopics();
    // this.getSubtopics();
  }

  getCourse(){

    if (this.isLogin){
      this.courseUser = new CourseUser();

      this.courseUser.fkUserId = this.appSrv.getCurrentUser().id;
      this.courseUser.fkCourseId = this.courseId;

      this.courseSrv.getCourseIsLogin(this.courseUser).subscribe(resp=>{
        this.course = resp;
      });

    }
    else{

      this.courseSrv.getById(this.courseId).subscribe(resp=>{
        this.course = resp;
        
    });
    }
  
}

getSkills(){
  this.fkCourse = String(this.courseId);//de int a string
  this.skillSrv.getAllbyCourse(this.fkCourse).subscribe(result => {
    
    if (Object.keys(result).length === 0)
    {
      this.msgSkills= "No hay informacion que mostrar";
      this.skills = null;
    }
    else{
      this.skills = result;
      this.msgSkills = null;
    }
  });
}

getTopics(){

  // this.msgSubtopics="No hay informacion que mostrar LOL";

  this.fkCourse = String(this.courseId);//de int a string
  this.topicSrv.getAllbyCourse(this.fkCourse).subscribe(result => {
    
    if (Object.keys(result).length === 0)
    {
      this.msgTopics= "No hay informacion que mostrar";
      this.topics = null;
    }
    else{
      this.topics = result;
      this.msgTopics = null;
    }

  });
   
}

  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
    document.getElementById("navbar-dat-training").style.display="none";
  }  

}
