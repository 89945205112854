import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppHandlerService } from './app.handler.service';
import { CoreService } from './core.service';
//import { Observable } from 'rxjs';
//import { Course } from '../shared/model/Course.model';
import { Course, CourseUser } from '../model/model.api';
import { Observable } from 'rxjs';

@Injectable({
    providedIn:'root'
})
export class CourseService extends CoreService<Course,number>{

    
    constructor(http: HttpClient,private appSrv: AppHandlerService){
        super(appSrv.getApiURI() + "Courses" ,http);
    }

    //traer cursos cuando el usuario este logueado y verificando que cursos tiene inscritos
    getCoursesIsLogin(id: string): Observable<Course[]> {
        
        return this.http.get<Course[]>(this.base + "/" + id + "/isEnroll");
      }


     //traer un curso cuando el usuario esta logueado y verificar si esta inscrito 
      getCourseIsLogin(formData) {
        return this.http.post<any>(this.base + '/getCourseIsLoginById', formData);
      }
     

}