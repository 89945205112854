import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-visualizacion-dashboard',
  templateUrl: './visualizacion-dashboard.component.html',
  styleUrls: ['./visualizacion-dashboard.component.css']
})
export class VisualizacionDashboardComponent implements OnInit {
  @Output() pageTitulo: EventEmitter<string> = new EventEmitter<"">();

  constructor() { }

  ngOnInit() {
    this.pageTitulo.emit("“Usted tiene los datos, nosotros hacemos que hablen para usted”");
  }

}
