import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppHandlerService } from './app.handler.service';
import { CoreService } from './core.service';
import { Skill, Ad } from '../model/model.api';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdService extends CoreService<Ad, number>{
    constructor(http: HttpClient, private appSrv: AppHandlerService) {
        super(appSrv.getApiURI() + "Ads", http);
    }


    // getAllbyCourse(courseId:string): Observable<Ad[]>{
    //     const params={
    //       "fkcourse": courseId
    //     };
    //     return this.getAll(null,params);
    //   }


    getAds(): Observable<Ad[]> {

        return this.http.get<Ad[]>(this.base + "/getAds");
    }
}