import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { InformationAbout } from '../model/constantes';
import { ContactInformation } from '../model/model.api';
import { AppHandlerService } from '../services/app.handler.service';
import { ContactInformationService } from '../services/contactinformation.service';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css'],
  providers: [ConfirmationService]
})
export class ContactInformationComponent implements OnInit {

  contactInformation: ContactInformation;
  contactInformationForm: FormGroup;
  titulo: string;
  errors: string[] = [];

  waitingResponse: boolean;

  messageSent: boolean;

  @Output() objectCreated = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder,
    private contactInformationSrv: ContactInformationService,
    private appSrv: AppHandlerService,
    private toastr: ToastrService) { }

  ngOnInit() {

    this.contactInformation = new ContactInformation();
    this.createForm();
  }


  createForm() {
    this.contactInformationForm = this.fb.group(this.contactInformation);
    this.contactInformationForm.controls['email'].setValidators([Validators.email, Validators.required, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}')]);
    this.contactInformationForm.controls['message'].setValidators(Validators.required);
    
  }

  resetForm() {
    this.contactInformation = new ContactInformation();
    this.contactInformationForm.reset(this.contactInformation);

  }

  save() {

    this.waitingResponse = true;
    //limpiar errores previos
    this.errors = [];

    //asignar valores a nuestro modelo de datos   
    this.assignValues();

    //salvar
    if (this.contactInformation.contactInfoId == 0) {

      this.contactInformationSrv.create(this.contactInformation).subscribe(resp => {
       
        this.waitingResponse = false;
        this.resetForm();

        this.messageSent=true;
        this.objectCreated.emit(true);
        // this.toastr.info(
        //   '<span data-notify="icon" class="nc-icon nc-check-2"></span><span data-notify="message">Mensaje <b>enviado</b></span>',
        //     "",
        //     {
        //       timeOut: 4000,
        //       closeButton: true,
        //       enableHtml: true,
        //       toastClass: "alert alert-success alert-with-icon",
        //       positionClass: "toast-bottom-center" 
        //     }
        //   );
      }, (err) => {
        console.log(err);
        this.errors = this.appSrv.getErrosMessages(err);
        this.waitingResponse = false;
      });
    } else {
      this.contactInformationSrv.update(this.contactInformation.contactInfoId, this.contactInformation).subscribe(resp => {
        this.waitingResponse = false;
      }, (err) => {
        this.errors = this.appSrv.getErrosMessages(err);
        this.waitingResponse = false;
      });
    }
  }

  assignValues() {

    Object.assign(this.contactInformation, this.contactInformationForm.value);
   
   this.contactInformation.fkInfoAboutId=InformationAbout.TrainingProgram;
    this.contactInformation.UsuarioCreacion = "Dat-Analytics";
  }


}
