import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { ToastrModule } from 'ngx-toastr';

import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import {MDBBootstrapModule} from 'angular-bootstrap-md';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from './app.component';
//import {RegistrationComponent} from './registration/registration.component'


//import { AgmCoreModule } from '@agm/core';

//External
//import  {NgxSpinnerModule} from 'ngx-spinner';

// PrimeNG
//import { CarouselModule } from 'primeng/carousel';
//import { DialogModule } from 'primeng/components/dialog/dialog';
//import { ConfirmDialogModule } from 'primeng/components/confirmdialog/confirmdialog';
//import {DropdownModule} from 'primeng/dropdown';

// APP
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';
import { MainSectionComponent } from './main-section/main-section.component';
 import { ListdashComponent } from './listdash/listdash.component';
import { MisionVisionComponent } from './mision-vision/mision-vision.component';
import { FooterComponent } from './footer/footer.component';
import { VisorInfoComponent } from './visor-info/visor-info.component';
import { NuestraEmpresaComponent } from './nuestra-empresa/nuestra-empresa.component';
import { EquipoDatComponent } from './equipo-dat/equipo-dat.component';
import { TecnologiaUtilizadaComponent } from './tecnologia-utilizada/tecnologia-utilizada.component';
import { VisualizacionDashboardComponent } from './visualizacion-dashboard/visualizacion-dashboard.component';
import { AnaliticasComponent } from './analiticas/analiticas.component';
import { PlataformaComponent } from './plataforma/plataforma.component';

import { ProductosComponent } from './productos/productos.component';
import { AnaliticaclientesComponent } from './analiticaclientes/analiticaclientes.component';
import { AnaliticaoperacionalComponent } from './analiticaoperacional/analiticaoperacional.component';
import { AnaliticaventasComponent } from './analiticaventas/analiticaventas.component';
import { AnaliticafinancieraComponent } from './analiticafinanciera/analiticafinanciera.component';
import { PiramideComponent } from './piramide/piramide.component';
import { LoginComponent } from './login/login.component';
import { NewsComponent } from './news/news.component';
import { PortafolioComponent } from './portafolio/portafolio.component';

import { RegistrationComponent } from './registration/registration.component';

import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';

import { UserService } from './shared/services/user.service';
import { UserComponent } from './user/user.component';
import { AuthInterceptor } from './shared/guards/auth.interceptor';
import { ReadNewsComponent } from './news/read-news/read-news.component';
import { DatTrainingComponent } from './dat-training/dat-training.component';
import { NavbarDatTrainingComponent } from './dat-training/navbar-dat-training/navbar-dat-training.component';
import { CoursesDatTrainingComponent } from './dat-training/courses-dat-training/courses-dat-training.component';
import { CourseComponent } from './dat-training/course/course.component';
import { CoverCourseComponent } from './dat-training/course/cover-course/cover-course.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { MyCoursesComponent } from './dat-training/my-courses/my-courses.component';
import { EditUserProfileComponent } from './user/edit-user-profile/edit-user-profile.component';
import { ContactoComponent } from './contacto/contacto.component';
import { CertificateComponent } from './dat-training/certificate/certificate.component';
import { PrincipalesClientesComponent } from './principales-clientes/principales-clientes.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { MsjPasswordComponent } from './user/forgot-password/msj-password/msj-password.component';
import { DatPremiumComponent } from './dat-premium/dat-premium.component';
import { ChangePwdComponent } from './user/change-pwd/change-pwd.component';
import { BannerServiceDeskComponent } from './banner-service-desk/banner-service-desk.component';
import { BannerDatTrainingComponent } from './banner-dat-training/banner-dat-training.component';
import { UserReviewsComponent } from './user-reviews/user-reviews.component';
import { AdsComponent } from './shared/ads/ads.component';
import { BannerProgramComponent } from './banner-program/banner-program.component';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { ToastrModule } from 'ngx-toastr';
import { PorqueElegirnosComponent } from './porque-elegirnos/porque-elegirnos.component';
import { PartnersComponent } from './partners/partners.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { EntrenamientoEnAnaliticaComponent } from './entrenamiento-en-analitica/entrenamiento-en-analitica.component';
import { ContactInformationComponent } from './shared/contact-information/contact-information.component';
import { DatTrendsComponent } from './productos/dat-trends/dat-trends.component';
import { DatPortalComponent } from './productos/dat-portal/dat-portal.component';

//import { ContactInformationComponent } from './contact-information/contact-information.component';



@NgModule({
  declarations: [
    AppComponent,
    TopNavBarComponent,
    MainSectionComponent,
    ListdashComponent,
    MisionVisionComponent,
    FooterComponent,
    VisorInfoComponent,
    NuestraEmpresaComponent,
    EquipoDatComponent,
    TecnologiaUtilizadaComponent,
    VisualizacionDashboardComponent, AnaliticasComponent, PlataformaComponent,  
    ProductosComponent, AnaliticaclientesComponent, AnaliticaoperacionalComponent, 
    AnaliticaventasComponent, AnaliticafinancieraComponent, PiramideComponent, 
    LoginComponent, NewsComponent, PortafolioComponent, 
    RegistrationComponent,  HomeComponent, UserComponent, ReadNewsComponent, 
    DatTrainingComponent, NavbarDatTrainingComponent, CoursesDatTrainingComponent, CourseComponent, 
    CoverCourseComponent, UserProfileComponent, MyCoursesComponent, EditUserProfileComponent, ContactoComponent, CertificateComponent, PrincipalesClientesComponent, PageNotFoundComponent, ForgotPasswordComponent, MsjPasswordComponent, DatPremiumComponent, ChangePwdComponent, BannerServiceDeskComponent, BannerDatTrainingComponent, UserReviewsComponent, AdsComponent, BannerProgramComponent, ApplicationFormComponent, PorqueElegirnosComponent, PartnersComponent, LoadingComponent, EntrenamientoEnAnaliticaComponent,
    ContactInformationComponent,
    DatTrendsComponent,
    DatPortalComponent,
  ],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    BrowserAnimationsModule,
    //CarouselModule, 
    FormsModule,ReactiveFormsModule, HttpClientModule,
    //DialogModule,ConfirmDialogModule, 
    //NgxSpinnerModule,
    //DropdownModule,
 
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDMcmHqfY3_rdxHMbv4FqS7no6x9WqIj4I'
    // }),
    // ToastrModule.forRoot({
    //   progressBar: true
    // }),
    ToastrModule.forRoot(),
    MDBBootstrapModule.forRoot()
    
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [UserService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent],
  exports: []
})

export class AppModule { }
