import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CurrentUser } from '../model/current-user'
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';


const jwtHelper = new JwtHelperService();
@Injectable({
  providedIn: 'root'
})
export class AppHandlerService {

  errors: string[] = [];
  _apiURI: string;

  keyToken: string = 'token';
  keyUsr: string = 'user';

  constructor(private router: Router) {
    this.errors = [];
    this._apiURI = 'http://localhost:5000/api/';

    this._apiURI='https://dat-analytics.com/api/';

     //this._apiURI = 'https://develop-training.dat-analytics.com/api/';
  }

  getApiURI() {
    return this._apiURI;
  }

  logIn(token: any) {
    //set token
    localStorage.setItem(this.keyToken, token);

    //set current user
    let access_token = this.getToken();
    var decoded_token = jwt_decode(access_token);
    let currentUsr: CurrentUser = {
      //roles:[],
      //permissions: [],
      //isAdmin: (decoded_token['isAdmin'] === "True"),  
      id: decoded_token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
      userName: decoded_token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
      email: decoded_token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
      fullName: decoded_token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'],
      urlProfilePicture: (decoded_token.UrlProfilePicture),
    };
    //console.log(currentUsr);

    // //set roles
    // var roles = decoded_token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];  
    // if(roles != undefined){
    //   if(typeof roles==="string"){
    //     currentUsr.roles.push(roles);
    //   }      
    //   else{
    //     Object.values(roles).forEach(r=>{
    //       currentUsr.roles.push(r as string);
    //     });
    //   }
    // }

    // //set permissions
    // var permissions = decoded_token['service.desk.api/permission'];  
    // if(permissions != undefined){
    //   if(typeof permissions==="string") {
    //     currentUsr.permissions.push(permissions);
    //   }
    //   else {
    //     Object.values(permissions).forEach(p=>{
    //       currentUsr.permissions.push(p as string);
    //     });
    //   }
    // }

    //set
    localStorage.setItem(this.keyUsr, JSON.stringify(currentUsr));

  }


  isLogin(): boolean {
    let token = this.getToken();
    if (token != null && !jwtHelper.isTokenExpired(token)) {
      return true;
    }
    else
      localStorage.removeItem('token');
    localStorage.removeItem('user');
    return false;
  }

  getCurrentUser(): CurrentUser {
    let currentUsr = JSON.parse(localStorage.getItem(this.keyUsr));
    return currentUsr;
  }

  getToken(): any {
    return localStorage.getItem(this.keyToken);
  }

  getErrosMessages(errRsp: HttpErrorResponse) {
    this.errors = [];
    if (errRsp.status === 400) {
      Object.values(errRsp.error.errors).forEach(err => {
        Object.values(err).forEach(msg => {
          this.errors.push(msg);
        });
      });
    } else {
      this.errors.push(errRsp.error.message);
    }
    return this.errors;
  }
}
