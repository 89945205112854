import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { News } from '../shared/model/model.api';
import { NewsService } from '../shared/services/news.service';
import { AppHandlerService } from '../shared/services/services.api';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  news: News[];

  @Output() public idMenu = new EventEmitter<any>();

  constructor( private newsSrv:NewsService,
               private appSrv:AppHandlerService

  ) { }
  
  //enviando a menu y noticia seleccionada a home
  updateMnSel(pOpt: number,idNews:number) {
    this.idMenu.emit({menuSelected:pOpt,NewsSelected:idNews});
  }

  ngOnInit() {

    //traer todas las noticias
    this.newsSrv.getAll().subscribe(resp=>{
      this.news = resp;
      //console.log(this.news);
    });
  }

}
