import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-equipo-dat',
  templateUrl: './equipo-dat.component.html',
  styleUrls: ['./equipo-dat.component.css']
})
export class EquipoDatComponent implements OnInit {
  @Output() pageTitulo: EventEmitter<string> = new EventEmitter<"">();

  constructor() { }

  ngOnInit() {
    this.pageTitulo.emit("“Nuestro objetivo es proveer una verdadera experiencia de analisis de informacion para nuestros clientes”");
  }


}
