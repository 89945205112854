import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Course } from 'src/app/shared/model/model.api';
import { AppHandlerService } from 'src/app/shared/services/services.api';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {


  @Output() idMenu = new EventEmitter<any>();

  isLogin : boolean;

  @Input () course: Course;
  
  constructor( private toastr: ToastrService,
              private appSrv: AppHandlerService,) { }

  ngOnInit() {
    this.isLogin=this.appSrv.isLogin();
  }


  updateMnSel(pOpt: number, curso:string) {
    //this.idMenu.emit(pOpt);
      this.idMenu.emit({menuSelected:pOpt});
      
      setTimeout(()=>{    
        this.toastr.info('Inicie sesion o registrese para inscribir curso '+curso,'',{
          positionClass: 'toast-bottom-left'
        });  
  }, 1000);
      
  }
}
