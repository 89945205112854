import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-plataforma',
  templateUrl: './plataforma.component.html',
  styleUrls: ['./plataforma.component.css']
})
export class PlataformaComponent implements OnInit {
  @Output() pageTitulo: EventEmitter<string> = new EventEmitter<"">();

  constructor() { }

  ngOnInit() {
    this.pageTitulo.emit("“Usted tiene los datos, nosotros hacemos que hablen para usted”");
  }

}
