export class InfoLandingWebsite {

  infoLandingId: number;

  urlTrainingImage: string;
  usuarioCreacion: string;
  fechaCreacion: Date;
  publicIdCloudinaryImg: string;




  constructor() {
    this.infoLandingId = 0;
    this.urlTrainingImage = null;
  }

}