import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../shared/model/model.api';
import { UserService, AppHandlerService } from '../../shared/services/services.api';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-change-pwd',
  templateUrl: './change-pwd.component.html',
  styleUrls: ['./change-pwd.component.css']
})
export class ChangePwdComponent implements OnInit {

  user: User;
  changePwdForm: FormGroup;
  errors: string[] = [];
  mnSel: number = 0;
  isLogin: boolean;
  isLoading: boolean;
  constructor(private fb: FormBuilder,
    private userSrv: UserService, 
    private appSrv: AppHandlerService,
    private toastr: ToastrService,
    private router: Router, private _location: Location ) { }

  ngOnInit() {

    this.isLogin=this.appSrv.isLogin();

    if (this.isLogin){
      this.init();
      this.createForm();
      this.resetForm();
    }
   else{
    this.router.navigate(['']);
   }

  }

  assignValues(){
    Object.assign(this.user,this.changePwdForm.value);
  }

  backClicked() {
    this._location.back();
  }
  
  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get('confirmPassword');    
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb.get('newPassword').value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else
        confirmPswrdCtrl.setErrors(null);
    }
  }

  createForm(){
    this.changePwdForm = this.fb.group(this.user,{ validator: this.comparePasswords });   
    //this.changePwdForm.controls['userName'].disable(); 
    //this.changePwdForm.controls['email'].disable();

    this.changePwdForm.controls['password'].setValidators([Validators.required,Validators.minLength(8)]);
    this.changePwdForm.controls['newPassword'].setValidators([Validators.required,Validators.minLength(8)]);
    this.changePwdForm.controls['confirmPassword'].setValidators(Validators.required);    
  }

  init(){
    this.user = new User();
    this.user.id = this.appSrv.getCurrentUser().id;    
    this.user.email = this.appSrv.getCurrentUser().email;
    this.user.userName = this.appSrv.getCurrentUser().userName;
    this.user.urlProfilePicture=this.appSrv.getCurrentUser().urlProfilePicture;
  }

  resetForm(){    
    this.changePwdForm.reset(this.user);
  }

  save(){   

    //limpiar errores previos
    this.errors = [];

    //asignar valores a nuestro modelo de datos   
    this.assignValues(); 
    this.isLoading= true;
    //salvar  
    this.userSrv.changePwd(this.user).subscribe((resp: any)=>{      
      if(resp.succeeded){

        setTimeout(()=>{    
          this.toastr.info('Contraseña actualizada con éxito ','Cambio de Contraseña',{
            positionClass: 'toast-top-center'
          });  
    }, 500);
    this.resetForm();
    this.router.navigateByUrl('/profile');
      }else{
        this.handlerError(resp);
      }      
    }, (err) => {       
      this.errors = this.appSrv.getErrosMessages(err);        
    });
  }

  handlerError(res:any ){
    this.errors=[];    
    res.errors.forEach(element => {
      this.errors.push(element.description);    
    });
  }

  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
    document.getElementById("navbar-dat-training").style.display="none";
  }
}
