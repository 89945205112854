import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-nuestra-empresa',
  templateUrl: './nuestra-empresa.component.html',
  styleUrls: ['./nuestra-empresa.component.css']
})
export class NuestraEmpresaComponent implements OnInit {
  @Output() pageTitulo: EventEmitter<string> = new EventEmitter<"">();
  urlVideo: string[] = ["assets/video/dat_about.mp4"];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.pageTitulo.emit("“Proveemos una verdadera experiencia de análisis de información”");
  }
  
  getVideoTag(file) {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<video id="daIntro" autoplay muted onloadedmetadata="this.muted = true" loop poster="assets/img/logooficial.png" style="min-width: 100%;min-height: 100%;height: auto;width: 100%;">
          <source src="${file}" type="video/mp4">Su explorador no soporta video HTML5.</source>
       </video>`
    );
  }

}
