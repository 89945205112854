import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationForm } from '../shared/model/applicationform.model';
import { ApplicationFormService } from '../shared/services/applicationform.service';
import { AppHandlerService } from '../shared/services/services.api';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.css']
})
export class ApplicationFormComponent implements OnInit {


  errors: string[] = [];
  applicationFormData: ApplicationForm;
  applicationForm: FormGroup;
  isLogin: boolean;
  mnSel: number = 0;
  id : string = "";
  sentSuccessfully:boolean;



  constructor(private appSrv : AppHandlerService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
    private applicationFormSrv: ApplicationFormService,
    ) { }

  ngOnInit() {

    document.getElementById("logoBlue").style.display = "none";

    this.applicationFormData = new ApplicationForm();

    this.isLogin=this.appSrv.isLogin();

    this.getForms();
    this.createForm();

    if (this.isLogin){
      this.id = this.appSrv.getCurrentUser().id;
    }
    // else{
    //   this.router.navigate(['']);
    // }
  }

  ngAfterViewChecked(){
    document.getElementById("logoBlue").style.display = "none";
  }
  assignValues(){
    Object.assign(this.applicationFormData,this.applicationForm.value);
   // console.log(this.applicationFormData)
  }

  createForm(){
    this.applicationForm = this.fb.group(this.applicationFormData);
    this.applicationForm.controls['fullName'].setValidators(Validators.required);
    this.applicationForm.controls['email'].setValidators([Validators.email, Validators.required, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}')]);
    this.applicationForm.controls['phoneNumber'].setValidators(Validators.pattern("^\\+(?:[0-9] ?){6,14}[0-9]$"));


  }

  save(){

    //limpiar errores previos
    this.errors = [];

    //console.log(this.userProfile);
    this.assignValues();

    //console.log(this.userProfile);

    this.applicationFormSrv.send(this.applicationFormData).subscribe((resp:any) =>{

      if (resp.succeeded || resp.errors == undefined) {
        setTimeout(()=>{
          this.toastr.info('Enviada con éxito ','Solicitud',{
            positionClass: 'toast-top-center'
          });
    }, 500);
    this.resetForm();
    this.router.navigateByUrl('/form');

    this.sentSuccessfully=true;
      }else{
        resp.errors.forEach(element => {
          this.toastr.error(element.description,'Envio fallido');
        });
      }

    },(err) => {
      this.errors = this.appSrv.getErrosMessages(err);
      console.log(this.errors);
    });


  }

  resetForm() {
    this.applicationFormData = new ApplicationForm();
    this.applicationForm.reset(this.applicationFormData);
  }

  handlerError(res:any ){
    this.errors=[];
    res.errors.forEach(element => {
      this.errors.push(element.description);
    });
  }

  getForms(){
    this.applicationFormSrv.getAll().subscribe(resp=>{


    })
  }

  new(){
    this.resetForm();
    this.sentSuccessfully=false;
  }

  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
   // document.getElementById("navbar-dat-training").style.display="none";
    document.getElementById("navbarCanvas-main").style.display = "none";
  }

}

