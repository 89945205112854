export class News {

    headline: string;
    abstract: string;
    content: string;
    urlCoverPage: string;
    usuarioCreacion: string;
    fechaCreacion: Date;


    constructor() {

        this.headline = null;
        this.abstract = null;
        this.content = null;
        this.urlCoverPage = null;
        this.usuarioCreacion = null;
        this.fechaCreacion = null;


    }

}



