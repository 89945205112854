import { Subtopic } from './subtopic.model';
export class Topic{
    topicId:number;
    codTopic : string;
    description : string;
    name : string;
    subtopics:Subtopic[];


   

constructor(){
  this.topicId = null;
  this.description = null;
    this.codTopic = null;
    this.name = null;
    this.subtopics = [];
  
   }

}