import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { UserComponent } from './user/user.component';
import { HomeComponent } from './home/home.component';
import { DatTrainingComponent } from './dat-training/dat-training.component';
import { CourseComponent } from './dat-training/course/course.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { MyCoursesComponent } from './dat-training/my-courses/my-courses.component';
import { EditUserProfileComponent } from './user/edit-user-profile/edit-user-profile.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { MsjPasswordComponent } from './user/forgot-password/msj-password/msj-password.component';
import {AuthGuard} from './shared/guards/auth.guard';
//import { NewsComponent } from './news/news.component';
import { ReadNewsComponent } from './news/read-news/read-news.component';
import { ChangePwdComponent } from './user/change-pwd/change-pwd.component';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { ContactInformationComponent } from './shared/contact-information/contact-information.component';
import { DatTrendsComponent } from './productos/dat-trends/dat-trends.component';
import { DatPortalComponent } from './productos/dat-portal/dat-portal.component';

//la vieja

const routes: Routes = [
  { path: '',component: HomeComponent},
  // { path: 'dat-training', component: DatTrainingComponent },
  { path: 'curso/:id', component: CourseComponent },
  { path: 'profile', component: UserProfileComponent, canActivate:[AuthGuard] }, //user auth
  { path: 'edit-profile', component: EditUserProfileComponent, canActivate: [AuthGuard] }, //user auth
  { path: 'change-pwd', component: ChangePwdComponent, canActivate: [AuthGuard] }, //user auth
  // { path: 'my-courses', component: MyCoursesComponent, canActivate: [AuthGuard]}, //user auth
  { path: 'forgot-password',component: ForgotPasswordComponent},
  { path: 'form',component: ApplicationFormComponent},

  { path: 'msj-password',component: MsjPasswordComponent},
  { path: 'contact',component: ContactInformationComponent},
  {path: 'news/:id', component: ReadNewsComponent},
  {path: 'dat-trends', component: DatTrendsComponent},
  {path: 'dat-portal', component: DatPortalComponent},
  { path: "**", component: PageNotFoundComponent },
  {path : 'not-found', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true
 })],
  exports:[RouterModule]
})
export class AppRoutingModule {}