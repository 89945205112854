import { Component, OnInit, Input } from '@angular/core';
//import * as AOS from 'aos';
//import { WOW } from 'wowjs/dist/wow.min';

@Component({
  selector: 'app-mision-vision',
  templateUrl: './mision-vision.component.html',
  styleUrls: ['./mision-vision.component.css']
})
export class MisionVisionComponent implements OnInit {
  @Input() showTitle: boolean = true;

  constructor() { }

  ngOnInit() {
    //AOS.init();
    //new WOW().init();
    
  }

}
