import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NewsService } from 'src/app/shared/services/news.service';
import { News, NewsImage } from 'src/app/shared/model/model.api';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//import { query } from '@angular/core/src/render3/query';
import { NewsImageService } from 'src/app/shared/services/services.api';

@Component({
  selector: 'app-read-news',
  templateUrl: './read-news.component.html',
  styleUrls: ['./read-news.component.css']
})
export class ReadNewsComponent implements OnInit {

  //@Input ('data') news: any;

  @Output() pageTitulo: EventEmitter<string> = new EventEmitter<"">();

  @Input() idNews:number;//trayendo id desde html visor

  @Input() idMenu:number;

  mnSel : number=0;
  newsId : number=0;
  news: News;
  fkNews: string;
  msgNewsImage: string;
  newsImages: NewsImage[];

  constructor( private newsSrv: NewsService,
              private newsImageSrv: NewsImageService,
              private _route: ActivatedRoute,
              private _location: Location) {
  }

  ngOnInit() {

    //capturando el parametro pasado por url
    this.newsId = +this._route.snapshot.paramMap.get('id');

    this.pageTitulo.emit("“Usted tiene los datos, nosotros hacemos que hablen para usted”");
    this.newsSrv.getById(this.newsId).subscribe(resp=>{
      this.news = resp;
  });

  this.getNewsImage();
}

// backClicked() {
//   this._location.back();
// }

getNewsImage(){

  // this.msgSubtopics="No hay informacion que mostrar LOL";

  this.fkNews = String(this.newsId);//de int a string
  this.newsImageSrv.getAllbyNews(this.fkNews).subscribe(result => {
    
    if (Object.keys(result).length === 0)
    {
      this.msgNewsImage= "No hay informacion que mostrar";
      this.newsImages = null;
    }
    else{
      this.newsImages = result;
      this.msgNewsImage = null;
    }

  });
   
}

UpdateMnSel(event) {
  this.mnSel = event.menuSelected;
  document.getElementById("navbar-dat-training").style.display="none";
}

}