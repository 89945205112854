import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IDashList } from './i-dash-list';

@Component({
  selector: 'app-listdash',
  templateUrl: './listdash.component.html',
  styleUrls: ['./listdash.component.css']
})
export class ListdashComponent implements OnInit {
  ListPowerBI: IDashList[];
  ListTableau: IDashList[];
  // @Output() DhSel: EventEmitter<IDashList> = new EventEmitter<IDashList>();

  constructor() { }

  ngOnInit() {
    this.initPowerBIList();
    this.initTableauList();
  }

  initPowerBIList() {
    this.ListPowerBI = [
      { Nom: 'Sales ScoreCard', Img: 'SalesScoreCard', Url: 'https://app.powerbi.com/view?r=eyJrIjoiZjQ0NWYwYzEtYjE1OC00MWExLWEwYjgtMGMwZmFmZTZiYTUyIiwidCI6IjdlY2M4YWEwLTgzMGUtNGQ4Ni1hZjc4LTgzYTNkY2MyNjIzOCIsImMiOjZ9' },
      { Nom: 'Flujo principal', Img: 'Flujo', Url: 'https://app.powerbi.com/view?r=eyJrIjoiZDBmNTc2NzMtZDM2ZS00ODJhLTlmZDctZjIwOTRiMWFkOWY5IiwidCI6ImY4NGI5MTI0LTBlYWItNGUwZS04YmE2LWNjZGQ2NTAxODYxMSJ9' }
    ];
  }

  initTableauList() {
    this.ListTableau = [
      { Nom: 'Finanzas', Img: 'Finanzas', Url: 'https://public.tableau.com/views/BankingRevenueSegmentation_0/Segmentation?:embed=y&:display_count=yes' },
      { Nom: 'Ingresos', Img: 'Ingresos', Url: 'https://public.tableau.com/views/CorporateFinanceDashboard/IncomeStatement?:embed=y&:display_count=yes' },
      { Nom: 'Almacenes', Img: 'Almacenes', Url: 'https://public.tableau.com/views/Warehouse_6/WarehouseDB?:embed=y&:display_count=yes' },
      { Nom: 'Comisiones por Ventas', Img: 'ComisionesVentas', Url: 'https://public.tableau.com/views/SalesCommissionModel_27/CommissionModel?:embed=y&:display_count=yes' },
      { Nom: 'Ventas', Img: 'Ventas', Url: 'https://public.tableau.com/views/Salesforce-Example_10_0/SalesDashboard?:embed=y&:display_count=yes' },
      { Nom: 'Operaciones', Img: 'Operaciones', Url: 'https://public.tableau.com/views/OperationsDashboard_2/OperationsDashboard?:embed=y&:display_count=yes' },
      { Nom: 'Segmentación de clientes', Img: 'SegmentacionClientes', Url: 'https://public.tableau.com/views/ProductMarketingandCustomerSegmentation/CustomerSegments?:embed=y&:display_count=yes' },
      { Nom: 'Cliente y Productos Analisis', Img: 'ClienteProducto', Url: 'https://public.tableau.com/views/CustomerProductAnalysis/Product?:embed=y&:display_count=yes' },
      { Nom: 'Metricas de Call Center', Img: 'MetricasCallCenter', Url: 'https://public.tableau.com/views/CallCenterMetrics/CallCenterMetrics?:embed=y&:display_count=yes' },
      { Nom: 'Network Analysis', Img: 'NetworkAnalysis', Url: 'https://public.tableau.com/shared/96GCW8PB5?:display_count=no' }
    ];
  }

  // setSelectDashboard(pSelDh: IDashList) {
  //   if (pSelDh != undefined) {
  //     this.DhSel.emit(pSelDh);
  //   }
  // }


}
