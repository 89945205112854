export class Ad{
    
  idad :number; 
  name : string;
  subname: string;
    description : string;
    link : string;
   deadline:Date;
   urlImage : string;
   fkTypeId :number; 
   fkStatusId :number; 
   estado:string;
   tipo


constructor(){
  this.idad = 0;
    this.name = null;
    this.description = null;
    this.subname = null;
    this.link=null;
    this.deadline=null;
    this.urlImage=null;
    this.fkStatusId=0;
    this.fkStatusId=0;
  
   }

}