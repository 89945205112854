import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  // @Output() idMenu: EventEmitter<number> = new EventEmitter<0>();
  @Output() idMenu = new EventEmitter<any>();
  fecha: Date;
  anio: number;

  constructor() { }

  updateMnSel(pOpt: number) {
    //this.idMenu.emit(pOpt);
    this.idMenu.emit({menuSelected:pOpt});
  }

  ngOnInit() {
    this.fecha = new Date;
    this.anio = this.fecha.getFullYear();
  }

}
