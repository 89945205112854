export class ContactInformation{
   
  contactInfoId:number;
  name:string;
  email:string;
  message:string;
  fkInfoAboutId:number;
  FechaCreacion: Date;
  FechaModificacion: Date;
  UsuarioCreacion: string;
  UsuarioModificado: string;


constructor(){
  this.contactInfoId = 0;
    this.name=null;
    this.email = null;
    this.message = null;
    this.fkInfoAboutId = 0;
    this.UsuarioCreacion="Dat-Analytics"
   }

}