import { Component, OnInit } from '@angular/core';
import { InfoLandingWebsite } from '../shared/model/model.api';
import { InfoLandingWebsiteService } from '../shared/services/services.api';

@Component({
  selector: 'app-banner-dat-training',
  templateUrl: './banner-dat-training.component.html',
  styleUrls: ['./banner-dat-training.component.css']
})
export class BannerDatTrainingComponent implements OnInit {
  infoLandingWebsite: InfoLandingWebsite;
  loading: boolean;

  showCourses:boolean=false;
  showClass:boolean=true;
  showLive:boolean=false;
  showChat:boolean=false;
  showCertificate:boolean=false;
  showEditor:boolean=false;


  constructor(private infoLandingWebsiteSrv: InfoLandingWebsiteService) { }

  ngOnInit() {
    this.infoLandingWebsite=new InfoLandingWebsite();
    this.getInfoWebsiteSite();
  }


  getInfoWebsiteSite() {

    this.infoLandingWebsiteSrv.getById(1).subscribe(resp => {
      this.infoLandingWebsite = resp;
      //console.log(this.infoLandingWebsite);
      //this.urlResourceSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.infoLanding.urlVideo1);
      this.loading = false;
    }
      , error => {
        this.loading = false;
        console.log(error);
      });

  }

  class(){
    this.showCourses=false;
    this.showClass= true;
    this.showLive=false;
    this.showChat=false;
    this.showCertificate=false;
    this.showEditor= false;
  }
  course(){
    this.showCourses=true;
    this.showClass= false;
    this.showLive=false;
    this.showChat=false;
    this.showCertificate=false;
    this.showEditor= false;
  }
  live(){
    this.showCourses=false;
    this.showClass= false;
    this.showLive=true;
    this.showChat=false;
    this.showCertificate=false;
    this.showEditor= false;
  }

  chat(){
    this.showCourses=false;
    this.showClass= false;
    this.showLive=false;
    this.showChat=true;
    this.showCertificate=false;
    this.showEditor= false;
  }

  certificate(){
    this.showCourses=false;
    this.showClass= false;
    this.showLive=false;
    this.showChat=false;
    this.showCertificate=true;
    this.showEditor= false;
  }

  Editor(){
    this.showCourses=false;
    this.showClass= false;
    this.showLive=false;
    this.showChat=false;
    this.showCertificate=false;
    this.showEditor= true;
  }


}
