import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-analiticaventas',
  templateUrl: './analiticaventas.component.html',
  styleUrls: ['./analiticaventas.component.css']
})
export class AnaliticaventasComponent implements OnInit {
  @Output() pageTitulo: EventEmitter<string> = new EventEmitter<"">();
  constructor() { }

  ngOnInit() {
    this.pageTitulo.emit("“Usted tiene los datos, nosotros hacemos que hablen para usted”");
  }

}
