import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Course, CourseUser } from 'src/app/shared/model/model.api';
import { ToastrService } from 'ngx-toastr';
import { AppHandlerService, CourseService, CourseUserService } from 'src/app/shared/services/services.api';

@Component({
  selector: 'app-cover-course',
  templateUrl: './cover-course.component.html',
  styleUrls: ['./cover-course.component.css']
})
export class CoverCourseComponent implements OnInit {

  @Input () course: Course;


  courseUser : CourseUser;

  isLogin:boolean;

  @Output() idMenu = new EventEmitter<any>();


  constructor(private toastr: ToastrService,
              private appSrv: AppHandlerService,
              private courseUserSrv : CourseUserService ) {

               }

  ngOnInit() {

    this.isLogin=this.appSrv.isLogin();
    
  }

 

  onEnroll(courseId:number){

    this.courseUser = new CourseUser();

    this.courseUser.fkUserId = this.appSrv.getCurrentUser().id;
    this.courseUser.fkCourseId = courseId;

   //actualizando curso
   this.course.enroll = true

    this.courseUserSrv.create(this.courseUser).subscribe((resp:any)=>{
        //this.courseUser = resp;

    });

  }

  unEnroll(courseId:number){

    this.courseUser = new CourseUser();

    this.courseUser.fkUserId = this.appSrv.getCurrentUser().id;
    this.courseUser.fkCourseId = courseId;
    
      //actualizando curso
      this.course.enroll=false

    this.courseUserSrv.unEnroll(this.courseUser).subscribe((resp:any)=>{
      //this.courseUser = resp;
    });

  }


  updateMnSel(pOpt: number, curso:string) {
    //this.idMenu.emit(pOpt);
      this.idMenu.emit({menuSelected:pOpt});
      
      setTimeout(()=>{    
        this.toastr.info('Inicie sesion o registrese para inscribir curso '+ curso,'',{
          positionClass: 'toast-bottom-left'
        });  
  }, 1000);
      
  }
}
