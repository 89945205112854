export enum EstadosDashboard {
    Privado = "Privado",
    Publico = "Publico"
}

export enum Sexo {
    Masculino = "M",
    Femenino = "F",

}

export enum InformationAbout {
    TrainingProgram = 1,
    contactInfo=2
}