import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-porque-elegirnos',
  templateUrl: './porque-elegirnos.component.html',
  styleUrls: ['./porque-elegirnos.component.css']
})
export class PorqueElegirnosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
