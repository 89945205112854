import { Component, OnInit } from '@angular/core';
import { AppHandlerService } from 'src/app/shared/services/services.api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-msj-password',
  templateUrl: './msj-password.component.html',
  styleUrls: ['./msj-password.component.css']
})
export class MsjPasswordComponent implements OnInit {

  isLogin: boolean
  constructor(private appSrv: AppHandlerService,
              private router: Router) { }

  ngOnInit() {
    this.isLogin=this.appSrv.isLogin();

    if (this.isLogin){
      this.router.navigate(['not-found']);
    }
  }

}
