import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dat-portal',
  templateUrl: './dat-portal.component.html',
  styleUrls: ['./dat-portal.component.css']
})
export class DatPortalComponent implements OnInit {

  constructor() { }

  mnSel: number = 0;

  ngOnInit(): void {
    document.getElementById("logoBlue").style.display = "none";
  }

  ngAfterViewChecked(){
    document.getElementById("logoBlue").style.display = "none";
  }

  UpdateMnSel(event) {
    this.mnSel = event.menuSelected;
    document.getElementById("navbarCanvas-main").style.display = "none";
  }



}
