import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

//import {NgxSpinnerService} from 'ngx-spinner';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
//consumir servicion de NgxSpinnerService
constructor (private router: Router ){

}

ngOnInit(){
  //this.spinner();
  this.router.events.subscribe((evt) => {
    if (evt instanceof NavigationEnd) {
      window.scrollTo(0, 0);
    }
  });
}

  onActivate(event) {
    window.scroll(0,0);

  }

 

  // spinner():void{
  //   this.spinnerSrv.show();
  //   setTimeout(() => {
  //     this.spinnerSrv.hide();
  //   }, 3000);
  // }

}